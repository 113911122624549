




















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { AdvertService } from '@/services/advert-service';
import { Message } from 'element-ui';
@Component({
  components: {

  }
})
export default class advertPage extends Vue {
  [x: string]: any;
  public form: any = {
      size: 10, current: 1, channelName: '', groupNames: ''
  };
  public datacreateTime: any[] = [];
  private tableData: any[] = [
  ];
  public visible: Boolean = false;
  public loading: Boolean = true;
  public errorMsg: String = '';
  @Inject(AdvertService) private advertService!: AdvertService;
  public async created(): Promise<void> {
    if (this.$route.query.id) {
      this.form.agentId = this.$route.query.id
      await this.getList();
    }
     
  }
  public search() {
      this.form.current = 1;
      this.getList()
  }
  // 重置
  public reset() {
      this.form = {
          size: 10,
          current: 1,
          agentId:this.$route.query.id,
          channelName: '',
          groupNames: ''
      };
      this.datacreateTime = [];
      this.getList();
  }
  //分页相关
  public handleSizeChange(size: number) {
      this.form.size = size;
      this.getList();
  }
  public handleCurrentChange(current: number) {
      this.form.current = current;
      this.getList();
  }

  //获取数据源
  public async getList() {
      var that = this;
      that.loading = true;
      if (that.datacreateTime && that.datacreateTime.length > 0) {
          that.form.startTime = that.datacreateTime[0]
          that.form.endTime = that.datacreateTime[1]
      } else {
          delete that.form.startTime
          delete that.form.endTime
      }
      const res = await this.advertService.getChannelList(this.form);
      that.tableData = res.records;
      that.form.total = res.total;
      that.form.pages = res.pages;
      that.form.size = res.size;
      that.form.current = res.current;
      that.loading = false;
  }
    //添加
  public addPage(){
    this.$router.push("/advertApplication?id="+this.form.agentId);
  }
  //修改
  public async handUpdate(row: any){
    this.$router.push("/advertApplication?id="+this.form.agentId+'&detailId='+row.id);
  }
  // 删除开屏
  public async deleItem(item: any) {  
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      const res = await this.advertService.deleteChannelInfo(item.id);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  private mounted() {
      document.onkeydown = (_e) => {
        let key = (window.event as any).keyCode;
          if (key == 13) {
              this.search()
          }
      }
  }
}
