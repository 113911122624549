import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class AdvertService {
  @Inject(HttpService) private http!: HttpService;
  
  //添加应用开屏
  public saveChannelInfo(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/channel/saveChannelInfo',param);
  }

  public selectChannelInfoDetail(id: Dict<any>): Promise<any> {
    return this.http.get(`/api/application/channel/selectChannelInfoDetail/${id}`);
  }
  //获取应用开屏列表
  public getChannelList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/channel/list',param);
  }
  //删除应用开屏列表
  public deleteChannelInfo(id: Dict<any>): Promise<any> {
    return this.http.get(`/api/application/channel/deleteChannelInfo/${id}`);
  }
  //获取分组
  public selectGroupList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/channel/selectGroupList',param);
  }
}
